import React from 'react';
import {
  AttachmentSlot,
  FieldValue,
  SavedData,
  SignatureRequest,
} from 'signer-app/signer-signature-document/types';
import { Field } from 'signer-app/types/editor-types';
import {
  SignerAppClient,
  useSignerAppClient,
} from 'signer-app/context/signer-app-client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { UnreachableError } from 'signer-app/utils/unreachable';

type SignatureRequestResponse = Awaited<
  ReturnType<
    SignerAppClient['signerApi']['signerAppSignatureRequestGetRequest']
  >
>;

export const extractSavedData = (field: Field): FieldValue<Field> => {
  switch (field.type) {
    case 'hyperlink':
    case 'rectangle':
    case 'image':
      return {
        id: field.id,
        type: field.type,
        image: null,
      };
    case 'date':
    case 'dropdown':
      return {
        id: field.id,
        type: field.type,
        value: field.value,
      };
    case 'checkbox':
    case 'radiobutton':
      return {
        id: field.id,
        type: field.type,
        checked: field.checked,
      };
    case 'initials':
    case 'signature':
      return {
        id: field.id,
        type: field.type,
        signature: field.signature,
      };
    case 'text':
      return {
        id: field.id,
        type: field.type,
        value: field.value,
        lines: field.lines,
        fontSize: field.fontSize,
      };
    // istanbul ignore next
    default:
      throw new UnreachableError(field);
  }
};

export const signExperienceKeys = {
  signerExperience: (signatureRequestId: string, accessCode: string) =>
    ['signer-experience', signatureRequestId, accessCode] as const,
};

export type AccessCodeResponse =
  | 'need-access-code'
  | 'invalid-access-code'
  | 'too-many-retries';

type AutoSaveReturn = {
  isFetching: boolean;
  data:
    | Omit<SignatureRequestResponse, 'signatureRequest'>
    | undefined
    | AccessCodeResponse;
  /**
   * This is the working copy of the signature request. It is optimistically
   * updated through `onFieldUpdate`
   */
  signatureRequest: SignatureRequest | undefined;
  onFieldUpdate?: (field: Field, updates: Partial<Field>) => void;
  uploadAttachment: (args: { attachmentSlotId: string; file: File }) => void;
  setAccessCode: (accessCode: string) => void;
  hasSetAccessCode: boolean;
};

export const SAVE_DELAY = NODE_ENV === 'test' ? 1 : 2000;
export function useAutoSaveSignerExperience(
  signatureRequestId: string,
): AutoSaveReturn {
  const client = useSignerAppClient();
  const queryClient = useQueryClient();
  const [accessCode, setAccessCode] = React.useState<string | null>(null);

  const queryKey = [
    'signer-experience',
    signatureRequestId,
    accessCode,
  ] as const;
  const { data, isFetching } = useQuery({
    queryKey,
    useErrorBoundary() {
      return true;
    },
    keepPreviousData: true,
    queryFn: () => {
      return client.signerApi
        .signerAppSignatureRequestGetRequest(
          signatureRequestId,
          accessCode || undefined,
        )
        .catch((e: Error): AccessCodeResponse => {
          if (e.message.includes('HTTP 403')) {
            if (!accessCode) {
              return 'need-access-code';
            }
            return 'invalid-access-code';
          }
          if (e.message.includes('HTTP 429')) {
            return 'too-many-retries';
          }
          throw e;
        });
    },
  });
  const signatureRequest =
    typeof data === 'string' ? undefined : data?.signatureRequest;

  const { mutate: uploadAttachment } = useMutation<
    unknown,
    unknown,
    {
      attachmentSlotId: string;
      file: File;
    }
  >({
    mutationKey: [signatureRequestId, accessCode, 'upload-attachment'] as const,
    mutationFn: async ({ attachmentSlotId, file }) => {
      const fileName = file.name;
      await client.signerApi.uploadAttachment({
        attachmentSlotId,
        file,
      });
      const uploadedAt = String(Date.now());
      queryClient.setQueryData(
        queryKey,
        (
          prev: SignatureRequestResponse | undefined,
        ): SignatureRequestResponse | undefined => {
          if (!prev) {
            return prev;
          }
          return {
            ...prev,
            signatureRequest: {
              ...prev.signatureRequest,
              attachmentSlots: prev.signatureRequest.attachmentSlots.map(
                (slot): AttachmentSlot => {
                  if (slot.id === attachmentSlotId) {
                    // @ts-expect-error
                    return {
                      ...slot,
                      uploaded_at: uploadedAt,
                      fileName,
                    };
                  }
                  // @ts-expect-error
                  return slot;
                },
              ),
            },
          };
        },
      );
    },
  });

  const fieldMutationKey = [signatureRequestId, accessCode, 'fields'] as const;
  const { mutate: mutateFieldData } = useMutation<unknown, unknown, SavedData>({
    mutationKey: fieldMutationKey,
    mutationFn: async (updates) => {
      await queryClient.cancelQueries(fieldMutationKey);
      const data = queryClient.getQueryData<SignatureRequestResponse>(queryKey);
      if (data) {
        const fields = data.signatureRequest.fields.map((field) => {
          const saved = updates[field.id];
          if (saved && saved.type === field.type && saved.id === field.id) {
            // `saved` is a subset of `field`, but I don't see any way to get
            // TypeScript to understand that if field is a checkbox, then saved is
            // ALSO a checkbox. So to limit the type casting, the function is
            // annotated and only this line is cast.
            return { ...field, ...saved } as any;
          }
          return field;
        });
        queryClient.setQueryData(queryKey, {
          ...data,
          signatureRequest: {
            ...data.signatureRequest,
            fields,
          },
        });
      }

      // @ts-ignore DPC_REMOVE I need to get this code removed from this repo
      await client.signerApi.signerAppSignatureRequestSaveRequest(
        signatureRequestId,
        updates,
      );
    },
  });

  const [draft, setDraft] = React.useState<SavedData | null>(null);
  React.useEffect(() => {
    if (draft) {
      const timeout = setTimeout(() => {
        mutateFieldData(draft);
        setDraft(null);
      }, SAVE_DELAY);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [draft, mutateFieldData]);

  const onFieldUpdate = React.useCallback(
    <T extends Field>(field: T, updates: Partial<T>): void => {
      const updatedField: Field = { ...field, ...updates };
      setDraft((prev): SavedData => {
        return {
          ...prev,
          [field.id]: extractSavedData(updatedField),
        };
      });
    },
    [],
  );

  const fields = React.useMemo((): SignatureRequest['fields'] => {
    const baseFields = signatureRequest?.fields;
    if (draft && baseFields) {
      return baseFields.map((field): Field => {
        const saved = draft[field.id];
        if (saved && saved.type === field.type && saved.id === field.id) {
          // `saved` is a subset of `field`, but I don't see any way to get
          // TypeScript to understand that if field is a checkbox, then saved is
          // ALSO a checkbox. So to limit the type casting, the function is
          // annotated and only this line is cast.
          return { ...field, ...saved } as any;
        }
        return field;
      });
    }

    return baseFields ?? [];
  }, [draft, signatureRequest?.fields]);

  return {
    onFieldUpdate,
    isFetching,
    data,
    setAccessCode: (newCode) => setAccessCode(newCode),
    hasSetAccessCode: accessCode !== null,
    uploadAttachment,
    // @ts-expect-error options is optional
    signatureRequest:
      signatureRequest == null
        ? undefined
        : ({
            ...signatureRequest,
            fields,
          } as typeof signatureRequest),
  };
}
